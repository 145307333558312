<template>
  <div
    class="
      d-flex
      flex-column
      min-vh-100
      justify-content-center
      align-items-center
      color
      mt-40
    "
    style="display: flex; justify-content: center; align-items: center"
  >
    <div class="login-box mb-5">
      <div class="login-box-logo" style="padding-bottom: 20px; width: 100%; display: flex; justify-content: center;">
        <img src="@/assets/images/WEM_logo_nobg.png" alt="Logo WEM" style="max-width: 65%"/>
      </div>

      <form @submit.prevent="forgotPassword" v-if="!done">
        <div class="form-group mb-5 mt-2 col-md-12 text-center">
          <h3
            style="
              font-size: 19.44px;
              font-family: Sofia Pro, Roboto, sans-serif;
              font-weight: 500;
            "
            v-html="$t('forgot-password.title')"
          />
          <p
            style="
              font-size: 12.96px;
              font-family: Sofia Pro, Roboto, sans-serif;
              font-weight: 400;
            "
            v-html="$t('forgot-password.description')"
          />
        </div>

        <div class="form-group mb-3 col-md-12">
          <!--          <input-->
          <!--            type="text"-->
          <!--            class="form-control"-->
          <!--            :placeholder="$t('forgot-password.username')"-->
          <!--            v-model="username"-->
          <!--          />-->
          <FormInput
            class="mb-7"
            :label="$t('forgot-password.username')"
            type="email"
            v-model:model="username"
            :field="username"
          />
        </div>

        <div class="form-group mb-3 col-md-12">
          <button
            type="submit"
            class="mt-5 w-full btn primary flex-1"
            style="text-transform: none"
          >
            {{ $t("forgot-password.send") }}
          </button>
        </div>
      </form>

      <div class="mt-2 mb-3 col-md-12 text-center" v-else>
        {{ $t("forgot-password.done") }}
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from "@/shared/components/FormInput.vue";

export default {
  name: "ForgotPassword",
  components: {
    FormInput,
  },
  data() {
    return {
      username: "",
      done: false,
    };
  },
  methods: {
    forgotPassword() {
      var params = new URLSearchParams();
      params.append("username", this.username);
      this.$store
        .dispatch("onboarding/forgotPassword", {
          params,
        })
        .then(() => {
          this.done = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-box {
  &-logo {
    width: 100%;

    img {
      width: 100%;
    }
  }

  width: 450px;
  background: #ece6e6;
  padding: 50px;
  max-width: 100%;
}
</style>
